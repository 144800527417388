import { NgModule, LOCALE_ID, Injector, ModuleWithProviders } from '@angular/core'
import { HTTP_INTERCEPTORS } from '@angular/common/http'

import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatMomentDatetimeModule } from '@mat-datetimepicker/moment'
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MatMomentDateModule, MomentDateAdapter } from '@angular/material-moment-adapter'

import { MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalBroadcastService, MsalGuard, MsalInterceptor, MsalModule, MsalRedirectComponent, MsalService } from '@azure/msal-angular'
import { AuthService } from './services/auth.service'
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core'
import { MSALGuardConfigFactory, MSALInstanceFactory, MSALInterceptorConfigFactory } from './services/auth.config'
import { PipesModule } from './modules/pipes/pipes.module'
import { MatAngularModule } from './modules/mat-angular/mat-angular.module'
import { SharedModule } from './modules/shared/shared.module'
import { CacheModule } from './services/caching/cache.module'
import { CachingInterceptor } from './services/caching/cache-interceptor'
import { CustomButtonComponent } from './components/custom-button/custom-button.component'

@NgModule({
  declarations: [CustomButtonComponent],
  imports: [MsalModule, PipesModule, MatAngularModule, SharedModule, CacheModule],
  exports: [PipesModule, MatAngularModule, SharedModule, CacheModule, CustomButtonComponent],
  providers: [],
  bootstrap: []
})
export class FoodopLibModule {
  static injector: Injector
  constructor(private _injector: Injector) {
    FoodopLibModule.injector = _injector
  }
  public static forRoot(environment: any, secured?: boolean): ModuleWithProviders<FoodopLibModule> {
    if (secured) {
      return {
        ngModule: FoodopLibModule,
        providers: [
          {
            provide: 'environment',
            useValue: environment
          },
          {
            provide: HTTP_INTERCEPTORS,
            useClass: CachingInterceptor,
            multi: true
          },
          {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true
          },
          {
            provide: MSAL_INSTANCE,
            useFactory: MSALInstanceFactory(environment)
          },
          {
            provide: MSAL_GUARD_CONFIG,
            useFactory: MSALGuardConfigFactory
          },
          {
            provide: MSAL_INTERCEPTOR_CONFIG,
            useFactory: MSALInterceptorConfigFactory(environment)
          },
          MsalService,
          MsalGuard,
          MsalBroadcastService,
          AuthService,
          MatDatepickerModule,
          MatMomentDateModule,
          MatMomentDatetimeModule,
          { provide: LOCALE_ID, useValue: window.location.href.replace(window.location.origin, '').slice(1, 3) },
          { provide: MAT_DATE_LOCALE, useValue: 'da-DK' },
          {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
          }
        ]
      }
    } else
      return {
        ngModule: FoodopLibModule,
        providers: [
          {
            provide: 'environment',
            useValue: environment
          },
          {
            provide: HTTP_INTERCEPTORS,
            useClass: CachingInterceptor,
            multi: true
          },
          MatDatepickerModule,
          MatMomentDateModule,
          MatMomentDatetimeModule,
          { provide: LOCALE_ID, useValue: window.location.href.replace(window.location.origin, '').slice(1, 3) },
          { provide: MAT_DATE_LOCALE, useValue: 'da-DK' },
          {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
          }
        ]
      }
  }
}
